<app-container>
  <app-content-block cssStyle="form">
    <div class="content">
      <h2>Your Connected Applications</h2>
      <ng-container *ngIf="!isLoading && permissions.length > 0; else loadingOrEmpty">
        <mat-list>
          <mat-list-item *ngFor="let permission of permissions">
            <div mat-list-avatar>
              <img [src]="permission.client_logo_url" alt="App Logo" />
            </div>
            <div class="info-section">
              <div mat-line>{{ permission.client_name }}</div>
              <div mat-line class="permission-date">
                Permission granted on: {{ permission.permission_granted_at | date: 'medium' }}
              </div>
            </div>
            <div class="button-section">
              <button mat-button color="warn" (click)="withdrawConsent(permission.session_id)">
                Withdraw Consent
              </button>
            </div>
          </mat-list-item>
        </mat-list>
      </ng-container>

      <ng-template #loadingOrEmpty>
        <div *ngIf="isLoading" class="loading-container">
          <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
        </div>
        <div *ngIf="!isLoading && permissions.length === 0">
          <p>You have no connected applications.</p>
        </div>
        <div *ngIf="errorMessage">
          <mat-icon color="warn">error</mat-icon>
          <p>{{ errorMessage }}</p>
        </div>
      </ng-template>
    </div>
  </app-content-block>
</app-container>
