import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { IExportLog } from '../../../../shared/model/log.model';
import { ExportLogService } from '../../../../shared/services/export-log.service';
import { tap } from 'rxjs/operators';
import { StatementService } from '../../../../shared/services/statement.service';

@Component({
  selector: 'app-export-logs',
  templateUrl: './export-logs.component.html',
  styleUrls: ['./export-logs.component.scss']
})
export class ExportLogsComponent implements OnInit {

  @Input()
  public bankAccountId!: number;

  public exportLogs$!: Observable<IExportLog[]>;

  constructor(
    private exportLogService: ExportLogService,
    private statementService: StatementService,
  ) { }

  public ngOnInit(): void {
    this.exportLogs$ = this.exportLogService.getAll(this.bankAccountId).pipe(
      tap(() => this.statementService.refresh())
    );
  }

}
