import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase/app';
import { from, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class MFAEnrollmentGuard implements CanActivate {
  constructor(
    private authService: AngularFireAuth,
    private router: Router,
    private snackBar: MatSnackBar,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authService.authState.pipe(
      switchMap((firebaseUser: firebase.User | null) => {
        if (firebaseUser) {
          // Ensure Firebase user state is up to date before checking
          return from(firebaseUser.reload()).pipe(
            map(() => firebaseUser),
            catchError(() => of(null)) // Handle any errors during reload
          );
        } else {
          return of(null);
        }
      }),
      map((firebaseUser: firebase.User | null) => {
        if (firebaseUser) {
          const enrolledFactors = firebaseUser.multiFactor.enrolledFactors;
          const hasMFAEnrolled = enrolledFactors && enrolledFactors.length > 0;

          if (hasMFAEnrolled) {
            // User has completed MFA enrollment
            return true;
          } else {
            // User has not completed MFA enrollment
            this.router.navigate(['/enroll-mfa'], { queryParams: { returnUrl: state.url } });
            this.snackBar.open('Multi-factor authentication is required', 'Dismiss', {
              duration: 15000,
              panelClass: ['notification-snackbar'],
            });
            return false;
          }
        } else {
          // No user is signed in, redirect to login page
          this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
          return false;
        }
      })
    );
  }
}
