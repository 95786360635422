<app-container>
  <app-content-block>
    <h1>Companies</h1>
    <div *ngIf="companies$ | async as companies">
      <table class="list dark">
        <tr *ngIf="companies.length > 0">
          <th>Company name</th>
          <th>Bank accounts</th>
        </tr>
        <tr
          *ngFor="let company of companies"
          [routerLink]="['/companies', company.id]"
          class="clickable"
        >
          <td>{{ company.name }}</td>
          <td>{{ company.numberOfBankAccounts }}</td>
        </tr>
        <tr *ngIf="companies.length === 0">
          <td class="message">No companies found. Click the "Create a new company" button to create one.</td>
        </tr>
      </table>

      <div class="table-bottom-options">
        <button class="create" [routerLink]="['/companies', 'create']">Create a new company</button>
      </div>
    </div>
    <div *ngIf="error$ | async as error">
      <h3>Error loading companies</h3>
      <div *ngIf="error?.error?.error?.includes('authenticated')">
        <p>This might be an authentication issue.</p>
        <p><a [routerLink]="['/logout']">Logout</a> to renew your authentication tokens.</p>
      </div>
    </div>
  </app-content-block>
</app-container>
