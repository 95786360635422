<app-container>
  <app-content-block cssStyle="form">
    <ng-container *ngIf="!isLoading && !errorMessage; else loadingOrError">
      <div class="content">
        <h2>Authorize Access</h2>
        <!-- User Pictogram and Logo -->
        <div class="user-logo-container">
          <div class="user-pictogram">
            <mat-icon>person</mat-icon>
          </div>
          <div class="dots"></div>
          <div class="app-logo">
            <img [src]="logoUrl" alt="App Logo" />
          </div>
        </div>
        <p>
          {{ clientName }} is requesting access to your Tactly account.
          {{ clientName }} wants permission to:
        </p>
        <ul>
          <li *ngFor="let scope of requestedScopes">
            {{ formatScope(scope) }}
          </li>
        </ul>

        <!-- Bank Account Selection -->
        <div class="selection-container" *ngIf="companyNames.length > 0; else noAccounts">
          <p>Please select the bank accounts you want to grant access to:</p>

          <!-- Select All / Deselect All Buttons -->
          <div class="select-buttons">
            <button mat-button (click)="selectAll()">Select All</button>
            <button mat-button (click)="deselectAll()">Deselect All</button>
          </div>

          <mat-selection-list [formControl]="bankAccountsControl">
            <mat-accordion>
              <mat-expansion-panel *ngFor="let companyName of companyNames">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    {{ companyName }}
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <!-- Headers -->
                <div class="account-headers">
                  <div class="account-name-header">Account Name</div>
                  <div class="account-number-header">Account Number</div>
                </div>

                <!-- Account List -->
                <mat-list-option *ngFor="let bankAccount of bankAccountsByCompany[companyName]" [value]="bankAccount">
                  <div class="account-row">
                    <div class="account-name">{{ bankAccount.name }}</div>
                    <div class="account-number">{{ bankAccount.account }}</div>
                  </div>
                </mat-list-option>
              </mat-expansion-panel>
            </mat-accordion>
          </mat-selection-list>
        </div>

        <ng-template #noAccounts>
          <p>No bank accounts available.</p>
        </ng-template>

        <div class="actions">
          <button mat-flat-button color="primary" (click)="accept()" [disabled]="isAcceptDisabled()">
            Allow
          </button>
          <button mat-flat-button color="warn" (click)="deny()">Deny</button>
        </div>
      </div>

    </ng-container>

    <ng-template #loadingOrError>
      <div class="loading-error-container">
        <mat-progress-spinner *ngIf="isLoading" mode="indeterminate"></mat-progress-spinner>
        <div *ngIf="errorMessage">
          <mat-icon color="warn">error</mat-icon>
          <p>{{ errorMessage }}</p>
        </div>
      </div>
    </ng-template>
  </app-content-block>
</app-container>
