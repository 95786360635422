import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

interface IPermission {
  client_id: string;
  session_id: string;
  permission_granted_at: string;
  permission_expires_at: string;
  client_name: string;
  client_logo_url: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthorizationService {
  oauthUrl = `${environment.api}/oauth`;

  constructor(private http: HttpClient) {}

  public accept(
    clientId: string,
    redirectUri: string,
    scope: string,
    state: string,
    bankAccounts: string[]
  ) {
    const url = `${this.oauthUrl}/accept`;
    return this.http.post<{ redirectUrl: string }>(url, {
      client_id: clientId,
      redirect_uri: redirectUri,
      scope,
      state,
      bank_accounts: bankAccounts,
    });
  }

  public deny(clientId: string, redirectUri: string, state: string) {
    const url = `${this.oauthUrl}/deny`;
    return this.http.post<{ redirectUrl: string }>(url, { client_id: clientId, redirect_uri: redirectUri, state});
  }

  public getClientInfo(clientId: string): Observable<any> {
    const url = `${this.oauthUrl}/client-info`;
    return this.http.get(url, { params: { client_id: clientId } });
  }

  public listPermissions(): Observable<IPermission[]> {
    const url = `${this.oauthUrl}/list-permissions`;
    return this.http.get<IPermission[]>(url);
  }

  public withdrawConsent(sessionId: string): Observable<void> {
    const url = `${this.oauthUrl}/withdraw-consent`;
    return this.http.post<void>(url, { session_id: sessionId });
  }
}

