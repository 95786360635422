import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ICompany } from '../model/company.model';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  constructor(private http: HttpClient) {}

  public get(companyId: number): Observable<ICompany> {
    const url = `${environment.api}/v2/companies/${companyId}`;
    return this.http.get<ICompany>(url);
  }

  public getAll(): Observable<ICompany[]> {
    const url = `${environment.api}/v2/companies`;
    return this.http.get<ICompany[]>(url);
  }

  public async create(company: ICompany): Promise<ICompany> {
    const url = `${environment.api}/v2/companies`;
    return this.http.post<ICompany>(url, company).toPromise();
  }

  public async update(company: ICompany): Promise<void> {
    const url = `${environment.api}/v2/companies/${company.id}`;
    return this.http.put<void>(url, company).toPromise();
  }

  public async delete(company: ICompany): Promise<void> {
    const url = `${environment.api}/v2/companies/${company.id}`;
    return this.http.delete<void>(url).toPromise();
  }
}
