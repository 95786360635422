import { NgModule } from '@angular/core';
import { LoginPage } from './pages/login/login.page';
import { AuthRoutingModule } from './auth-routing.module';
import { LogoutPage } from './pages/logout/logout.page';
import { SharedModule } from '../../shared/shared.module';
import { ProfilePage } from './pages/profile/profile.page';
import { SignupLinkPage } from './pages/signup-link/signup-link.page';
import { SendEmailVerificationPage } from './pages/send-email-verification/send-email-verification.page';
import { PasswordResetPage } from './pages/password-reset/password-reset.page';
import { EnrollMfaPage } from './pages/enroll-mfa/enroll-mfa.page';
import { MfaSettingsPage } from './pages/mfa-settings/mfa-settings.page';
import { ReauthenticationPage } from './pages/reauthentication/reauthentication.page';
import { AuthorizationPage } from './pages/authorization/authorization.page';
import { PermissionsPage } from './pages/permissions/permissions.page';

@NgModule({
  declarations: [
    LoginPage,
    LogoutPage,
    ProfilePage,
    SignupLinkPage,
    SendEmailVerificationPage,
    PasswordResetPage,
    EnrollMfaPage,
    MfaSettingsPage,
    ReauthenticationPage,
    AuthorizationPage,
    PermissionsPage,
  ],
  imports: [
    AuthRoutingModule,
    SharedModule,
  ],
  exports: [],
})
export class AuthModule { }
