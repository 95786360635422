import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthorizationService } from 'src/app/shared/services/authorization.service';
import { BankAccountService } from 'src/app/shared/services/bank-account.service';
import { FormControl } from '@angular/forms';
import { IBankAccount } from 'src/app/shared/model/bank-account.model';

@Component({
  selector: 'app-authorization-page',
  templateUrl: './authorization.page.component.html',
  styleUrls: ['./authorization.page.component.scss'],
})
export class AuthorizationPage implements OnInit {
  // Existing properties
  clientId: string = '';
  redirectUri: string = '';
  scope: string = '';
  state: string = '';
  clientName: string = '';
  logoUrl: string = '';
  requestedScopes: string[] = [];
  isLoading: boolean = true;
  errorMessage: string = '';
  bankAccountsControl = new FormControl([]);
  bankAccountsByCompany: { [companyName: string]: IBankAccount[] } = {};
  companyNames: string[] = [];

  constructor(
    private route: ActivatedRoute,
    private authorizationService: AuthorizationService,
    private bankAccountService: BankAccountService
  ) {}

  ngOnInit(): void {
    // Extract query parameters and initialize authorization
    this.route.queryParams.subscribe((params) => {
      this.clientId = params['client_id'];
      this.redirectUri = params['redirect_uri'];
      this.scope = params['scope'] || '';
      this.state = params['state'] || '';

      if (!this.clientId || !this.redirectUri) {
        this.errorMessage = 'Invalid authorization request.';
        this.isLoading = false;
        return;
      }

      // Fetch client information
      this.authorizationService.getClientInfo(this.clientId).subscribe(
        (response) => {
          this.clientName = response.client_name;
          this.logoUrl = response.logo_url;
          this.requestedScopes = this.scope.split(' ');

          // Fetch bank accounts after getting client info
          this.fetchBankAccounts();
        },
        (error) => {
          console.error('Error getting client info:', error);
          this.errorMessage = 'Failed to load client information.';
          this.isLoading = false;
        }
      );
    });
  }

  fetchBankAccounts(): void {
    this.bankAccountService.getAll().subscribe(
      (bankAccounts) => {
        this.groupBankAccountsByCompany(bankAccounts);
        this.isLoading = false;
      },
      (error) => {
        console.error('Error fetching bank accounts:', error);
        this.errorMessage = 'Failed to load bank accounts.';
        this.isLoading = false;
      }
    );
  }

  groupBankAccountsByCompany(bankAccounts: IBankAccount[]): void {
    this.bankAccountsByCompany = bankAccounts.reduce((groups, account) => {
      const companyName = account.companyName || 'Other';
      if (!groups[companyName]) {
        groups[companyName] = [];
      }
      groups[companyName].push(account);
      return groups;
    }, {} as { [companyName: string]: IBankAccount[] });

    this.companyNames = Object.keys(this.bankAccountsByCompany);
  }

  accept(): void {
    const selectedAccounts = this.bankAccountsControl.value.map((account: any) => account.id);

    if (selectedAccounts.length === 0) {
      this.errorMessage = 'Please select at least one bank account.';
      return;
    }

    this.authorizationService
      .accept(this.clientId, this.redirectUri, this.scope, this.state, selectedAccounts)
      .subscribe(
        (response) => {
          window.location.href = response.redirectUrl;
        },
        (error) => {
          console.error('Error accepting:', error);
          if (error.error.error === 'invalid_state') {
            this.errorMessage = 'State is invalid or has expired. Please try again.';
          } else if (error.error.error === 'server_error') {
            this.errorMessage = 'Server error occurred. Please try again later.';
          } else {
            this.errorMessage = 'An error occurred while processing your request. Please try again.';
          }
        }
      );
  }

  deny(): void {
    this.authorizationService.deny(this.clientId, this.redirectUri, this.state).subscribe(
      (response) => {
        window.location.href = response.redirectUrl;
      },
      (error) => {
        console.error('Error denying:', error);
        this.errorMessage = 'Your request to deny access has been processed, but there was an issue with the redirection.';
      }
    );
  }

  isAcceptDisabled(): boolean {
    return this.bankAccountsControl.value.length === 0;
  }

  formatScope(scope: string): string {
    const scopeDescriptions: { [key: string]: string } = {
      read_accounts: 'Read your bank data',
      // Add more scope descriptions as needed
    };
    return scopeDescriptions[scope] || scope;
  }

  selectAll(): void {
    const allAccounts = this.companyNames.reduce((acc, companyName) => {
      return acc.concat(this.bankAccountsByCompany[companyName]);
    }, [] as IBankAccount[]);
    this.bankAccountsControl.setValue(allAccounts);
  }

  deselectAll(): void {
    this.bankAccountsControl.setValue([]);
  }
}
