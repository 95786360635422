import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { IImportLog } from '../../../../shared/model/log.model';
import { ImportLogService } from '../../../../shared/services/import-log.service';
import { tap } from 'rxjs/operators';
import { StatementService } from '../../../../shared/services/statement.service';

@Component({
  selector: 'app-import-logs',
  templateUrl: './import-logs.component.html',
  styleUrls: ['./import-logs.component.scss']
})
export class ImportLogsComponent implements OnInit {

  @Input()
  public bankAccountId!: number;

  public importLogs$!: Observable<IImportLog[]>;

  constructor(
    private importLogService: ImportLogService,
    private statementService: StatementService,
  ) { }

  public ngOnInit(): void {
    this.importLogs$ = this.importLogService.getAll(this.bankAccountId).pipe(
      tap(() => this.statementService.refresh())
    );
  }

}
