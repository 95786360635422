import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IBankAccount, IBankAccountWithoutID } from '../model/bank-account.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BankAccountService {
  constructor(private http: HttpClient) {}

  public getCollection(): string {
    return 'bank-accounts';
  }

  public get(bankAccountId: number): Observable<IBankAccount> {
    const url = `${environment.api}/v2/bank-accounts/${bankAccountId}`;
    return this.http.get<IBankAccount>(url);
  }

  public getAll(): Observable<IBankAccount[]> {
    const url = `${environment.api}/v2/bank-accounts`;
    return this.http.get<IBankAccount[]>(url);
  }

  public getByCompanyId(companyId: number | string): Observable<IBankAccount[]> {
    const url = `${environment.api}/v2/companies/${companyId}/bank-accounts`;
    return this.http.get<IBankAccount[]>(url);
  }

  public async create(bankAccount: IBankAccountWithoutID): Promise<void> {
    const url = `${environment.api}/v2/bank-accounts`;
    return this.http.post<void>(url, bankAccount).toPromise();
  }

  public async update(bankAccount: IBankAccount): Promise<void> {
    const url = `${environment.api}/v2/bank-accounts/${bankAccount.id}`;
    return this.http.put<void>(url, bankAccount).toPromise();
  }

  public async delete(bankAccount: IBankAccount): Promise<void> {
    const url = `${environment.api}/v2/bank-accounts/${bankAccount.id}`;
    return this.http.delete<void>(url).toPromise();
  }
}
