import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { IStatement } from '../model/statement.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { startWith, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StatementService {

  constructor(private http: HttpClient) {}

  private refreshSubject = new Subject();

  public getByBankAccountId(bankAccountId: number, limit = 100): Observable<IStatement[]> {
    return this.refreshSubject.asObservable().pipe(
      startWith(null),
      switchMap(() => this.http.get<IStatement[]>(
      `${environment.api}/v2/bank-accounts/${bankAccountId}/statements`
      )));
  }

  public refresh(): void {
    this.refreshSubject.next();
  }

}
