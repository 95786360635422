import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthorizationService } from 'src/app/shared/services/authorization.service';
import { ConfirmDialogComponent, IConfirmData } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';

interface IPermission {
  client_id: string;
  session_id: string;
  permission_granted_at: string;
  permission_expires_at: string;
  client_name: string;
  client_logo_url: string;
}

@Component({
  selector: 'app-permissions',
  templateUrl: './permissions.page.html',
  styleUrls: ['./permissions.page.scss'],
})
export class PermissionsPage implements OnInit, OnDestroy {
  permissions: IPermission[] = [];
  isLoading: boolean = true;
  errorMessage: string = '';
  private subscriptions: Subscription = new Subscription();

  constructor(
    private authorizationService: AuthorizationService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.getPermissions();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getPermissions(): void {
    const permissionsSubscription = this.authorizationService.listPermissions().subscribe(
      (permissions) => {
        this.permissions = permissions;
        this.isLoading = false;
      },
      (error) => {
        console.error('Error fetching permissions:', error);
        this.errorMessage = 'Failed to load permissions.';
        this.isLoading = false;
      }
    );
    this.subscriptions.add(permissionsSubscription);
  }

  withdrawConsent(sessionId: string): void {
    const confirmData: IConfirmData = {
      title: 'Confirm Withdraw Consent',
      message: `
        You are about to withdraw consent for this application. This will revoke access and you may need to reauthorize the application to use it again.
        Are you sure you want to proceed?
      `,
      confirmButtonText: 'Withdraw Consent',
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: confirmData,
    });

    const dialogSubscription = dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        const withdrawSubscription = this.authorizationService.withdrawConsent(sessionId).subscribe(
          () => {
            // Remove the permission from the list after successful withdrawal
            this.permissions = this.permissions.filter(
              (permission) => permission.session_id !== sessionId
            );
          },
          (error) => {
            console.error('Error withdrawing consent:', error);
            this.errorMessage = 'An error occurred while withdrawing consent.';
            this.snackBar.open('Failed to withdraw consent', 'Close', {
              duration: 3000,
              panelClass: ['error-snackbar'],
            });
          }
        );
        this.subscriptions.add(withdrawSubscription);
      }
    });
    this.subscriptions.add(dialogSubscription);
  }
}
