import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../../shared/services/auth.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-login-page',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss']
})
export class LoginPage implements OnInit, OnDestroy {
  private authSubscription?: Subscription;
  private initialCheckDone = false;
  private returnUrl = '/';

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

    this.authSubscription = this.authService.authState.subscribe(async (user) => {
      if (!this.initialCheckDone) {
        this.initialCheckDone = true;
        if (user && !user.emailVerified) {
          await this.authService.logout();
          this.router.navigate(['/login']);
          return;
        }
      }

      if (user && user.emailVerified) {
        this.navigate();
      } else if (user && !user.emailVerified) {
        this.router.navigate(['/send-email-verification']);
      }
    });
  }

  ngOnDestroy(): void {
    this.authSubscription?.unsubscribe();
  }

  private navigate(): void {
    this.router.navigateByUrl(this.returnUrl);
  }
}



